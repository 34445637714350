html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, span, a {
  font-family: Montserrat, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.navbar-item {
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

.breadcrumb-link {
  color: black;
}

.footer-navbar-item {
  color: rgba(115, 115, 115, 1);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.footer-navbar-item:hover {
  text-decoration: underline;
}

.navbar-item:hover,
.breadcrumb-link:hover {
  color: var(--amplify-components-button-primary-background-color);
}

.navbar-icon:hover path {
  fill: var(--amplify-components-button-primary-background-color);
}

.socmed-link:hover p,
.q360s-link:hover {
  text-decoration: underline;
}

.q360s-link {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(115, 115, 115, 1);
}

@media only screen and (min-width: 767px) and (max-width: 1279px) {
  .work-image:nth-child(6n+4),
  .work-image:nth-child(6n+3) {
    grid-area: span 2
  }

  .work-image:nth-child(11n+2),
  .work-image:nth-child(7) {
    grid-area: span 1/1;
  }
}

@media only screen and (min-width: 1280px) {
  .work-image:nth-child(17n+2),
  .work-image:nth-child(9n+2) {
    grid-area: span 1/1;
  }
  
  .work-image:nth-child(9n+8) {
    grid-area: span 1/2
  }
  
  .work-image:nth-child(3n+3) {
    grid-area: span 2
  }
}

ul {
  padding-left: 1.5rem;
  list-style: initial;
}

.amplify-table {
  border: none;
}

.amplify-table__th {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(115, 115, 115, 1);
  text-transform: uppercase;
  border: none;
  padding-bottom: 0;
}

.amplify-table__td {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(115, 115, 115, 1);
  border: none;
  border-bottom: 1px solid rgba(115, 115, 115, 1);
}